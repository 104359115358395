import React from 'react';

import { Button, Typography } from '@mui/material';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import ParalaxTitle from '../components/ParalaxTitle';
import imgBackground from '../images/fondo1.jpeg';
import '../assets/css/Contact-Form-Clean.css';
import '../assets/css/main.css';

function Contact() {
  return (
    <Layout>
      <SEO title="Contacto" />
      <ParalaxTitle text="Contacto" imgBackground={imgBackground} />
      <div className="container">
        <div className="contact-clean">
          {/* eslint-disable-next-line react/no-unknown-property */}
          <form name="contact" method="POST" netlify-honeypot="website" data-netlify="true">
            <Typography mb={4} variant="h4" sx={{ fontWeight: 700, textAlign: 'center' }}>
              Contáctame
            </Typography>
            <div className="form-group">
              {/* habilita el formulario en netlify */}
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="website" />
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Nombre"
                required
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="email"
                placeholder="Correo electronico"
                required
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                placeholder="Mensaje"
                rows={14}
                required
              />
            </div>
            <div className="form-group">
              <Button variant="contained" size="large" type="submit">
                Enviar
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
