import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const StyledParalaxTitle = styled.div`
  height: 100%;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .paralax_title {
    text-align: center;
    padding: 4rem 0;
    margin: 0;
  }

  .paralax_title > span {
    background-color: #111111b3;
    padding: 10px 30px;
  }
`;

export default function ParalaxTitle(props) {
  return (
    <StyledParalaxTitle className="mb-5" style={{ backgroundImage: `url(${props.imgBackground})` }}>
      <Typography color="white" variant="h2" className="paralax_title">
        <span>{props.text}</span>
      </Typography>
    </StyledParalaxTitle>
  );
}
